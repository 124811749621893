import React, { useEffect } from "react"

const Landing = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "/js/animations.js"
    script.async = true

    document.body.appendChild(script)

    const script2 = document.createElement("script")
    script2.src = "https://assets.calendly.com/assets/external/widget.js"
    script2.async = true

    document.body.appendChild(script2)
    return () => {
      document.body.removeChild(script)
      document.body.removeChild(script2)
    }
  }, [])

  return (
    <>
      {/* navbar */}
      <nav className="fixed left-0 right-0 flex justify-between items-center px-7 lg:px-32 py-5 lg:py-8 text-base lg:text-lg font-medium backdrop-blur-lg z-[999999]">
        <Icon name="logo" className="w-12 lg:w-auto" />
        <div className="flex gap-5 items-center">
          <a
            href="#services"
            className="hidden lg:block btn px-4 lg:px-5 py-2 lg:py-3 rounded"
          >
            Our Services
          </a>
          <a
            href="https://calendly.com/wantace-ai/consultation-call"
            className="px-4 lg:px-5 py-2 lg:py-3 bg-primary text-white rounded btn"
          >
            Book Free Audit Call
          </a>
        </div>
      </nav>
      {/* hero */}
      <section className="h-[80vh] flex flex-col lg:flex-row lg:gap-20 items-center text-lg font-medium px-7 lg:px-32 mb-10 pt-16 lg:pt-56">
        <div className="lg:hidden pl-8">
          <div id="geo-globe2">
            <canvas id="canvas2" className="scale-90"></canvas>
          </div>
        </div>
        <div className="-mt-60 lg:-mt-0 lg:w-1/2 flex flex-col gap-5 items-center lg:items-start">
          <span className="text-4xl lg:text-6xl font-semibold">Wantace AI</span>
          <p className="text-lg lg:text-xl text-center lg:text-left lg:w-[450px]">
            Leverage wantace’s comprehensive{" "}
            <span className="text-primary">AI development</span> expertise to
            maximize your business potential
          </p>
          <div className="mt-5 flex flex-col lg:flex-row gap-5">
            <a
              href="https://calendly.com/wantace-ai/consultation-call"
              className="px-5 py-3 bg-primary text-white rounded text-center btn"
            >
              Let's talk
            </a>
            <a href="#services" className="px-5 py-3 border rounded btn">
              Our Services
            </a>
          </div>
        </div>
        <div className="hidden lg:block w-1/2 animation-wrapper">
          <div class="sphere-animation">
            <svg
              class="sphere"
              viewBox="0 0 440 440"
              stroke="rgba(80,80,80,.35)"
            >
              <defs>
                <linearGradient
                  id="sphereGradient"
                  x1="5%"
                  x2="5%"
                  y1="0%"
                  y2="15%"
                >
                  <stop stop-color="#EFEFEF" offset="0%" />
                  <stop stop-color="#B1B1B1" offset="50%" />
                  <stop stop-color="#929292" offset="100%" />
                </linearGradient>
              </defs>
              <path d="M361.604 361.238c-24.407 24.408-51.119 37.27-59.662 28.727-8.542-8.543 4.319-35.255 28.726-59.663 24.408-24.407 51.12-37.269 59.663-28.726 8.542 8.543-4.319 35.255-28.727 59.662z" />
              <path d="M360.72 360.354c-35.879 35.88-75.254 54.677-87.946 41.985-12.692-12.692 6.105-52.067 41.985-87.947 35.879-35.879 75.254-54.676 87.946-41.984 12.692 12.692-6.105 52.067-41.984 87.946z" />
              <path d="M357.185 356.819c-44.91 44.91-94.376 68.258-110.485 52.149-16.11-16.11 7.238-65.575 52.149-110.485 44.91-44.91 94.376-68.259 110.485-52.15 16.11 16.11-7.239 65.576-52.149 110.486z" />
              <path d="M350.998 350.632c-53.21 53.209-111.579 81.107-130.373 62.313-18.794-18.793 9.105-77.163 62.314-130.372 53.209-53.21 111.579-81.108 130.373-62.314 18.794 18.794-9.105 77.164-62.314 130.373z" />
              <path d="M343.043 342.677c-59.8 59.799-125.292 91.26-146.283 70.268-20.99-20.99 10.47-86.483 70.269-146.282 59.799-59.8 125.292-91.26 146.283-70.269 20.99 20.99-10.47 86.484-70.27 146.283z" />
              <path d="M334.646 334.28c-65.169 65.169-136.697 99.3-159.762 76.235-23.065-23.066 11.066-94.593 76.235-159.762s136.697-99.3 159.762-76.235c23.065 23.065-11.066 94.593-76.235 159.762z" />
              <path d="M324.923 324.557c-69.806 69.806-146.38 106.411-171.031 81.76-24.652-24.652 11.953-101.226 81.759-171.032 69.806-69.806 146.38-106.411 171.031-81.76 24.652 24.653-11.953 101.226-81.759 171.032z" />
              <path d="M312.99 312.625c-73.222 73.223-153.555 111.609-179.428 85.736-25.872-25.872 12.514-106.205 85.737-179.428s153.556-111.609 179.429-85.737c25.872 25.873-12.514 106.205-85.737 179.429z" />
              <path d="M300.175 299.808c-75.909 75.909-159.11 115.778-185.837 89.052-26.726-26.727 13.143-109.929 89.051-185.837 75.908-75.908 159.11-115.778 185.837-89.051 26.726 26.726-13.143 109.928-89.051 185.836z" />
              <path d="M284.707 284.34c-77.617 77.617-162.303 118.773-189.152 91.924-26.848-26.848 14.308-111.534 91.924-189.15C265.096 109.496 349.782 68.34 376.63 95.188c26.849 26.849-14.307 111.535-91.923 189.151z" />
              <path d="M269.239 267.989c-78.105 78.104-163.187 119.656-190.035 92.807-26.849-26.848 14.703-111.93 92.807-190.035 78.105-78.104 163.187-119.656 190.035-92.807 26.849 26.848-14.703 111.93-92.807 190.035z" />
              <path d="M252.887 252.52C175.27 330.138 90.584 371.294 63.736 344.446 36.887 317.596 78.043 232.91 155.66 155.293 233.276 77.677 317.962 36.521 344.81 63.37c26.85 26.848-14.307 111.534-91.923 189.15z" />
              <path d="M236.977 236.61C161.069 312.52 77.867 352.389 51.14 325.663c-26.726-26.727 13.143-109.928 89.052-185.837 75.908-75.908 159.11-115.777 185.836-89.05 26.727 26.726-13.143 109.928-89.051 185.836z" />
              <path d="M221.067 220.7C147.844 293.925 67.51 332.31 41.639 306.439c-25.873-25.873 12.513-106.206 85.736-179.429C200.6 53.786 280.931 15.4 306.804 41.272c25.872 25.873-12.514 106.206-85.737 179.429z" />
              <path d="M205.157 204.79c-69.806 69.807-146.38 106.412-171.031 81.76-24.652-24.652 11.953-101.225 81.759-171.031 69.806-69.807 146.38-106.411 171.031-81.76 24.652 24.652-11.953 101.226-81.759 171.032z" />
              <path d="M189.247 188.881c-65.169 65.169-136.696 99.3-159.762 76.235-23.065-23.065 11.066-94.593 76.235-159.762s136.697-99.3 159.762-76.235c23.065 23.065-11.066 94.593-76.235 159.762z" />
              <path d="M173.337 172.971c-59.799 59.8-125.292 91.26-146.282 70.269-20.991-20.99 10.47-86.484 70.268-146.283 59.8-59.799 125.292-91.26 146.283-70.269 20.99 20.991-10.47 86.484-70.269 146.283z" />
              <path d="M157.427 157.061c-53.209 53.21-111.578 81.108-130.372 62.314-18.794-18.794 9.104-77.164 62.313-130.373 53.21-53.209 111.58-81.108 130.373-62.314 18.794 18.794-9.105 77.164-62.314 130.373z" />
              <path d="M141.517 141.151c-44.91 44.91-94.376 68.259-110.485 52.15-16.11-16.11 7.239-65.576 52.15-110.486 44.91-44.91 94.375-68.258 110.485-52.15 16.109 16.11-7.24 65.576-52.15 110.486z" />
              <path d="M125.608 125.241c-35.88 35.88-75.255 54.677-87.947 41.985-12.692-12.692 6.105-52.067 41.985-87.947C115.525 43.4 154.9 24.603 167.592 37.295c12.692 12.692-6.105 52.067-41.984 87.946z" />
              <path d="M109.698 109.332c-24.408 24.407-51.12 37.268-59.663 28.726-8.542-8.543 4.319-35.255 28.727-59.662 24.407-24.408 51.12-37.27 59.662-28.727 8.543 8.543-4.319 35.255-28.726 59.663z" />
            </svg>
          </div>
        </div>
      </section>
      {/* about */}
      <Section
        title="About Us"
        style={{
          background: "url('/assets/bg.png')",
          backgroundPosition: "top",
          backgroundSize: "cover",
          paddingTop: "10rem",
          paddingBottom: "8rem",
        }}
      >
        <p className="text-center text-base lg:text-xl text-para px-0 lg:px-28">
          Wantace AI serves as your comprehensive partner in AI development,
          facilitating the process from initial conception to final production
          and beyond. We exceed the remit of conventional machine learning
          specialists by assisting both burgeoning startups and established
          enterprises in the design and creation of avant-garde AI products that
          bolster efficiency and accelerate growth trajectories. We provide
          expert navigation through the complete developmental process, adeptly
          translating your innovative ideas into market-ready, actionable
          solutions. Our extensive cross-industrial expertise enables us to aid
          you in forging a cutting-edge product that significantly contributes
          to the success of your enterprise.
        </p>
      </Section>
      {/* services */}
      <Section id="services" title="Our Services">
        <div className="flex items-center">
          <div className="hidden lg:block w-[64%]">
            <div id="geo-globe">
              <canvas id="canvas" className="scale-150"></canvas>
            </div>
          </div>
          <div className="lg:w-[36%] flex flex-col gap-8">
            <Service
              title="GPT-3 | GPT-4 Development"
              body="Specializing in GPT-3 and GPT-4, our agency crafts bespoke AI solutions, from high-quality content and intelligent chatbots to precise model tuning, propelling your business into the future of automation."
              icon="icon"
            />
            <Service
              title="AI Consulting / Auditing"
              body="Our agency offers expert AI consulting and auditing, delivering tailored strategies and ensuring optimal, ethical AI use to revolutionize your business operations and propel you towards a data-driven future."
              icon="icon3"
            />
            <Service
              title="Automation Implementation"
              body="We excel in Automation Implementation, seamlessly integrating advanced automated systems into your business workflows to boost efficiency, accuracy, and scalability, thereby fostering growth and innovation."
              icon="icon2"
            />
          </div>
        </div>
      </Section>
      {/* strategies */}
      <section className={"py-20 px-5 lg:px-20 target-div"}>
        <h2 className="text-center font-medium text-3xl lg:text-5xl mb-12">
          Potential Strategies
        </h2>
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 flex flex-col gap-8">
            <Strategy
              title="Customer Service. "
              body="Handling common customer inquiries 24/7, providing instant responses and freeing up time."
              icon="strategies icons"
            />
            <Strategy
              title="Sales Assistance. "
              body="Guiding customers through the buying process, providing product recommendations based on user inputs and past buying behavior.
"
              icon="strategies icons (1)"
            />
            <Strategy
              icon="strategies icons (2)"
              title="Lead Generation. "
              body="Engaging website visitors, gather contact information, and qualify leads based on pre-set criteria."
            />
            <Strategy
              icon="strategies icons (3)"
              title="Personalized Marketing. "
              body="Deliver personalized content and offers based on user interactions and data, helping to improve conversion rates."
            />
            <Strategy
              icon="strategies icons (4)"
              title="Cost Reduction. "
              body="Automating repetitive tasks, chatbots can help reduce operational costs."
            />
            <Strategy
              icon="strategies icons (5)"
              title="Data Analysis. "
              body="Analyze customer data to identify trends, preferences, and areas for improvement."
            />
            <Strategy
              icon="strategies icons (6)"
              title="Booking and Scheduling. "
              body="Handle appointments, bookings, and schedule changes, providing convenience for both the business and the customers."
            />
            <Strategy
              icon="strategies icons (7)"
              title="Employee Onboarding and Training. "
              body="Assist in onboarding new employees by providing necessary information and answering frequently asked questions."
            />
          </div>
          <div className="lg:w-1/2">
            <Icon name="strategies" png className="w-full -ml-4 lg:-mt-0" />
          </div>
        </div>
      </section>

      {/* case study */}
      <Section title="Case Study">
        <div className="flex flex-col lg:flex-row mb-8">
          <div className="lg:w-1/2">
            <h3 className="text-xl lg:text-3xl font-medium mb-5">
              AI Chatbot for Martial Arts SaaS Business
            </h3>
            <div className="flex flex-col gap-2 text-base lg:text-xl text-para">
              <p>- Helping potential customers find perfect services</p>
              <p>- Collect valuable information</p>
              <p>- Direct traffic to book calls</p>
              <p>- Capture leads for future</p>
            </div>
          </div>
          <div className="lg:w-1/2 mt-10 lg:mt-0 border rounded p-3 flex flex-col gap-3 text-base lg:text-lg text-para">
            <span>Increase in bookings</span>
            <div className="border rounded flex gap-1 overflow-hidden">
              <div className="w-[64%] bg-primary"></div>
              <span className="text-primary text-lg lg:text-xl font-medium">
                +64%
              </span>
            </div>
            <span>Increase in market share</span>
            <div className="border rounded flex gap-1 overflow-hidden">
              <div className="w-[25%] bg-primary"></div>
              <span className="text-primary text-lg lg:text-xl font-medium">
                +25%
              </span>
            </div>
            <span>
              Cost Savings on Labour/Errors{" "}
              <span className="text-primary text-lg lg:text-xl font-medium">
                + 3500 USD/Month
              </span>{" "}
            </span>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <Icon name="img1" png className="lg:w-1/2" />
          <div className="flex gap-2">
            <Icon name="img2" png className="w-1/2" />
            <Icon name="img3" png className="w-1/2" />
          </div>
        </div>
      </Section>
      {/* working */}
      <section className="py-10 px-7 lg:px-32">
        <div className="border rounded flex flex-col lg:flex-row gap-20 p-3 lg:p-10">
          <div className="lg:w-1/2">
            <h3 className="text-center text-2xl lg:text-3xl font-medium mb-5">
              Book A Call With Us
            </h3>
            <div
              class="calendly-inline-widget"
              data-url="https://calendly.com/wantace-ai"
              style={{ minWidth: "100%", height: "700px" }}
            ></div>
          </div>
          <div className="lg:w-1/2">
            <h3 className="text-center text-2xl lg:text-3xl font-medium mb-20">
              How It Works
            </h3>
            <Icon name="works" png className="w-full" />
          </div>
        </div>
      </section>
      {/* contact */}
      <Section id="contact" title="Connect With Us" className="target-div2">
        <form className="max-w-[822px] w-full mx-auto flex flex-col gap-8">
          <div className="flex flex-col lg:flex-row gap-8">
            <input
              type="text"
              className="lg:w-1/2 border-b border-gray-300 py-1 text-base lg:text-xl"
              placeholder="First Name"
            />
            <input
              type="text"
              className="lg:w-1/2 border-b border-gray-300 py-1 text-base lg:text-xl"
              placeholder="Last Name"
            />
          </div>
          <div className="flex flex-col lg:flex-row gap-8">
            <input
              type="text"
              className="lg:w-1/2 border-b border-gray-300 py-1 text-base lg:text-xl"
              placeholder="Subject"
            />
            <input
              type="text"
              className="lg:w-1/2 border-b border-gray-300 py-1 text-base lg:text-xl"
              placeholder="Your email"
            />
          </div>
          <div>
            <h4
              className="text-gray-400 text-base lg:text-xl mb-1"
              htmlFor="desc"
            >
              How can we help you?
            </h4>
            <textarea
              id="desc"
              rows="10"
              className="w-full border border-gray-300 rounded text-base lg:text-xl"
            ></textarea>
          </div>
          <div>
            <h4
              className="text-gray-400 text-base lg:text-xl mb-1"
              htmlFor="desc"
            >
              What is your budget for this project?
            </h4>{" "}
            <select className="w-full border-b border-gray-300 py-1 text-base lg:text-xl">
              <option value="Less than $500">Less than $500</option>
              <option value="$1,000 - $2,500">$1,000 - $2,500</option>
              <option value="$2,500 - $5,000">$2,500 - $5,000</option>
              <option value="$10,000 - $25,000">$10,000 - $25,000</option>{" "}
              <option value="$50,000 +">$50,000 +</option>
            </select>
          </div>

          <div className="mt-10 flex justify-center">
            <button className="text-xl  font-medium px-5 py-3 bg-primary text-white rounded btn">
              Send
            </button>
          </div>
        </form>
      </Section>

      <footer>
        <div className="bg-primary px-7 lg:px-32 text-white py-10 lg:py-32 flex flex-col gap-10 items-center text-xl font-medium text-center">
          <span className="text-4xl lg:text-6xl">
            Unlocking Efficiency and <br /> Innovation through AI
          </span>
          <p className="font-normal">
            By revolutionizing Industries with Intelligent <br /> Automation
            Solutions
          </p>
          <div className="flex flex-col lg:flex-row gap-5">
            <a
              href="https://calendly.com/wantace-ai/consultation-call"
              className="px-5 py-3 bg-white text-black rounded btn"
            >
              Get Started
            </a>
            <a
              href="#contact"
              className="px-5 py-3 border border-white rounded btn"
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className="bg-black px-7 lg:px-32 text-white pt-10 lg:pt-20 pb-5">
          <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start gap-10 mb-44">
            <Icon name="logo_big" className="w-[60%] lg:w-auto" />
            <div className="flex gap-20">
              <div>
                <h4 className="text-xl font-medium mb-5">Address</h4>
                <h6 className="text-lg">London, UK</h6>
              </div>
              <div>
                <h4 className="text-xl font-medium mb-5">Contact</h4>
                <h6 className="text-lg">nick@wantace.ai</h6>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row justify-between text-base lg:text-lg font-medium gap-10">
            <span className="text-center">
              Copyright © 2023 Wantace Tech Vantage. All rights reserved.
            </span>
            <div className="text-para text-center">
              <a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a>{" "}
              | <a href="#">Cookie Settings</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

const Section = ({ title, children, style, className, id }) => (
  <section
    id={id}
    className={"pt-10 pb-10 lg:pt-32 lg:pb-20 px-7 lg:px-32 " + className}
    style={style}
  >
    <h2 className="text-center font-medium text-3xl lg:text-5xl mb-8 lg:mb-12">
      {title}
    </h2>
    {children}
  </section>
)

const Service = ({ title, icon, body }) => (
  <div className="border rounded-lg flex items-center p-3 gap-3">
    <div className="flex-none">
      <Icon name={icon} />
    </div>
    <div>
      <h3 className="text-lg lg:text-xl font-medium mb-3">{title}</h3>
      <p className="text-para text-sm lg:text-base">{body}</p>
    </div>
  </div>
)

const Strategy = ({ title, icon, body }) => (
  <div className="flex gap-3">
    <div className="flex-none">
      <Icon name={icon} />
    </div>
    <div>
      <span className="text-lg lg:text-xl font-medium">{title}</span>
      <span className="text-para text-base lg:text-lg">{body}</span>
    </div>
  </div>
)

const Icon = ({ name, png, className }) => (
  <img
    src={`/assets/${name}.${png ? "png" : "svg"}`}
    alt={name}
    className={className}
  />
)

export default Landing
